/*
 * Servizio che si occupa della gestione dei Learning Plan
*/

module LearningPlanService {
    let app = angular.module("app");

    app.factory("LearningPlanService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Recupera tutti i Learning Plan dell'utente loggato
            getAllMyLearningPlans: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-all-my-learning-plans/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Conta quanti sono i Learning Plan dell'utente loggato
            countAllMyLearningPlans: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-all-my-learning-plans', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Aggiunge un oggetto al Learning Plan
            addChilds: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/add-childs', {
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Cancella uno specifico Learning Plan
            deleteLearningPlan: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/delete-learning-plan/:itemId', {
                itemId: "@itemId"
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        }; 
        return serv;
    }]);
}